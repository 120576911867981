import React, {
  /* useRef, useEffect, */ useState /*, useDebugValue */,
} from "react";
import "./LeafletMap.css";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  useMapEvents
} from "react-leaflet";
// import { getPaths } from '../../data/paths';
import { vildmarksleden } from "../../data/Vildmarksleden";

export default function LeafletMap(props) {
  const hardCodedPaths = [
    {
      Id: 1,
      Name: "Vildmarksleden",
      Type: "trail",
      Color: "purple",
      OriginalColor: "purple",
      Geometry: {
        Coordinates: vildmarksleden,
      },
      GettingThere: [
        {
          Name: "Getting there by car",
          Type: "transportation",
          Geometry: {
            Coordinates: [
              [57.703207484213074, 12.039095163345339],
              [57.70314153889362, 12.039062976837158],
              [57.70330788654682, 12.038236856460571],
              [57.70294969298613, 12.037094235420229],
              [57.7037892931121, 12.036589980125429],
              [57.704216249532784, 12.03606426715851],
              [57.70407011065337, 12.03500747680664],
              [57.70440536956016, 12.034090161323547],
            ],
          },
          pathOptions: { color: "red", weight: 6, dashArray: "10 10" },
        },
        {
          Name: "Getting there by tram",
          Type: "transportation",
          Geometry: {
            Coordinates: [
              [57.703207484213074, 12.039095163345339],
              [57.70314153889362, 12.039062976837158],
              [57.70326189168766, 12.03831195831299],
              [57.70428534223674, 12.036595344543459],
              [57.70422803305192, 12.0360803604126],
              [57.70473235076613, 12.035973072052002],
              [57.70566073546634, 12.033913135528566],
              [57.706176494460145, 12.03181028366089],
              [57.70593580784385, 12.030222415924074],
              [57.70552319849402, 12.029857635498047],
              [57.70613064951373, 12.028956413269045],
              [57.70618795568771, 12.026295661926271],
              [57.70591288522551, 12.024965286254885],
              [57.70578681056538, 12.024235725402832],
            ],
          },
          pathOptions: { color: "red", weight: 6, dashArray: "30 10" },
        },
      ],
    },
    {
      Id: 2,
      Name: "Skatås - Lilla Delsjön",
      Type: "trail",
      Color: "yellow",
      OriginalColor: "yellow",
      Geometry: {
          Coordinates: [[57.70195652260933,12.041530609130861],[57.69085895852642,12.049770355224611],[57.68755654768946,12.06453323364258]]
      },
      Family: {
        Parent: {
          Id: 4,
          Name: "Skatåsleden"
        },
        Siblings: [3],
        Number: 1
      }
    },
    {
      Id: 3,
      Name: "Lilla Delsjön - Mölnlyckemotet",
      Type: "trail",
      Color: "yellow",
      OriginalColor: "yellow",
      Geometry: {
          Coordinates: [[57.68737307159596,12.065563201904299],[57.68645567719409,12.085647583007814],[57.68214361241423,12.088394165039064],[57.674710977585256,12.084617614746096]]
      },
      Family: {
        Parent: {
          Id: 4,
          Name: "Skatåsleden"
        },
        Siblings: [2],
        Number: 2
      }
    },
    {
      Id: 4,
      Name: "Skatåsleden",
      Type: "parent",
      Geometry: {
          Coordinates: [[57.68737307159596,12.065563201904299],[57.68645567719409,12.085647583007814],[57.68214361241423,12.088394165039064],[57.674710977585256,12.084617614746096]]
      }
    },
  ];

  const [lng /*setLng*/] = useState(12.10000);
  const [lat /*setLat*/] = useState(57.69500);
  const [zoom /*setZoom*/] = useState(13);
  const position = [lat, lng];
  const [currentlySelectedPath, setcurrentlySelectedPath] = useState(null);
  const [associatedPaths, setAssociatedPaths] = useState(null);
  const [paths, setPaths] = useState(hardCodedPaths);
  // const [loaded /*setLoaded*/] = useState(true);
  const [, /*buttonClicked*/ setbuttonClicked] = useState(false);
  const [recordPositions, setRecordPositions] = useState(false);
  const [editingPath, setEdititingPath] = useState(false);
  const [clickedPositions, setClickedPositions] = useState([]);
  const [, /*clickedPath*/ setClickedPath] = useState(false);
  // const [uneditedPath, setUneditedPath] = useState([]);
  const [pathBeingEdited, setPathBeingEdited] = useState([]);

  // useEffect(() => {
  //     let fetching = true;
  //     getPaths()
  //         .then(items => {
  //             if (fetching) {
  //                 setPaths(...paths, items)
  //             }
  //         })
  //     setLoaded(true)
  //     return () => fetching = false;
  // }, [setPaths, setLoaded])

  function selectPath(path) {
    setcurrentlySelectedPath(path);
    setClickedPath(true);
    console.log(`Type: ${path.Type}, getting there: ${path.GettingThere}, logic: ${path.GettingThere !== undefined}`)
    if (path.Type === "trail"){
      if(path.GettingThere !== undefined)
        setAssociatedPaths(path.GettingThere);
      else setAssociatedPaths(null);
    }

    const updatedPaths = paths.map(updatingPolyLine => {
      if(updatingPolyLine.Id === path.Id){
        return { ...updatingPolyLine, ...{ Color: 'orange' }};
      }
      return {...updatingPolyLine, ...{ Color: updatingPolyLine.OriginalColor}}
    })

    setPaths(updatedPaths);
  }

  function clearPath() {

    const updatedPaths = paths.map(updatingPolyLine => {
      if(updatingPolyLine.Id === currentlySelectedPath.Id){
        return { ...updatingPolyLine, ...{ Color: currentlySelectedPath.OriginalColor }};
      }

      return updatingPolyLine;
    })

    setPaths(updatedPaths);
    
    setcurrentlySelectedPath(null);
    setAssociatedPaths(null);
  }

  function startRecordingPositions() {
    setRecordPositions(true);
    setClickedPositions([]);
  }

  function stopRecordingPositions() {
    setRecordPositions(false);
  }

  function resetPositionsArray() {
    setClickedPositions(null);
  }

  function startEditingPath() {
    setEdititingPath(true);
    // setUneditedPath(currentlySelectedPath.Geometry.Coordinates);
    setPathBeingEdited(currentlySelectedPath.Geometry.Coordinates);
  }

  function stopEditingPath() {
    setEdititingPath(false);
  }

  function addAssociatedPaths() {
    setbuttonClicked(true);
  }

  function MapEvents() {
    useMapEvents({
        click(e) {
          // let clickedPosition = [e.latlng.lat, e.latlng.lng]
          if (recordPositions) {
            setClickedPositions(clickedPositions => [...clickedPositions, [e.latlng.lat, e.latlng.lng]])
          }
        },
        // mousedown(e) {
        //     console.log('mouse busson is pushed')
        // },
        // mouseup(e) {
        //     console.log('mouse button is released, moveFrom is')
        //     console.log(moveFrom)
        // }
    });
  }

  return (
    <>
      <MapContainer center={position} zoom={zoom} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <MapEvents />

        { // Show the original path
        editingPath && (
          <>
            {/* <Polyline
              key="originalPath"
              positions={uneditedPath}
              pathOptions={{ color: "orange", weight: 3 }}
            >
            </Polyline>

            <Polyline
              key="pathBeingEdited"
              positions={pathBeingEdited}
              pathOptions={{ color: "green", weight: 6 }}
            >
            </Polyline> */}
            
            {pathBeingEdited.map((coordinate, index) => (
              // console.log(`Editing path pos ${index}  with coords ${coordinate}`),
              <Marker
                key={index}
                draggable={true}
                position={coordinate}
                eventHandlers={{
                  // Docs https://leafletjs.com/reference.html#marker
                  // Working example https://www.youtube.com/watch?v=LpsWvv2inS0
                  // React leaflet example https://react-leaflet.js.org/docs/example-draggable-marker/ 
                  // move: (e) => {
                  //   console.log("Marker in pos " + index + " was moved from " + e.oldLatLng + " to " + e.latlng);
                  //   console.log(`Pos ${index} value was ${pathBeingEdited[index]}`)
                  //   var path = pathBeingEdited;
                  //   path[index] = [e.latlng.lat, e.latlng.lng];
                  //   setPathBeingEdited(path);
                  //   console.log(`Pos ${index} value is now ${pathBeingEdited[index]}`)
                  // },
                  // movestart: (e) => {
                  //   console.log(e) // same coord as moveend :(
                  // },
                  moveend: (e) => {
                    console.log(e.target._latlng)
                    
                    let tempPath = pathBeingEdited; 
                    tempPath[index] = [e.target._latlng.lat, e.target._latlng.lng];
                    setPathBeingEdited(tempPath);
                  }
                }} />
            ))}
          </>
        )}

        {!editingPath && paths.map((path, index) => (
          <Polyline
            key={path.Id}
            positions={path.Geometry.Coordinates}
            pathOptions={{ color: path.Color, weight: 6 }}
            eventHandlers={{
              click: () => {
                selectPath(path);
              },
              mouseover: (e) => {
                e.target.setStyle({ weight: 12 });
              },
              mouseout: (e) => {
                e.target.setStyle({ weight: 6 });
              },
            }}
          >
            {/* <ChangeCoordinates /> */}
          </Polyline>
        ))}

        {/* This is what we're really going to use when it's from Elasticsearch */}
        {/* {loaded && !editingPath &&
          paths.map((path) => (
            <Polyline
              key={path.Name}
              positions={path.Geometry.Coordinates.map((item) => {
                return [item[1], item[0]];
              })}
              pathOptions={{ color: "orange", weight: 6 }}
              eventHandlers={{
                click: () => {
                  props.setPath(path);
                },
                mouseover: (e) => {
                  e.target.setStyle({ color: "black" });
                },
              }}
            />
          ))} */}

        {!editingPath && associatedPaths &&
          associatedPaths.map((path) => (
            <Polyline
              key={path.Name}
              positions={path.Geometry.Coordinates}
              pathOptions={path.pathOptions}
              eventHandlers={{
                click: () => {
                  selectPath(path);
                },
                mouseover: (e) => {
                  e.target.setStyle({ weight: 12 });
                },
                mouseout: (e) => {
                  e.target.setStyle({ weight: 6 });
                },
              }}
            />
          ))}
      </MapContainer>

      <div>
        Action bar:&nbsp;
        {currentlySelectedPath && (
          <>
            <button onClick={clearPath}>Unselect path</button>&nbsp;
          </>
        )}
        {!recordPositions && (
          <>
            <button onClick={startRecordingPositions}>
              Start recording coordinates
            </button>
          </>
        )}&nbsp;
        {recordPositions && (
          <button onClick={stopRecordingPositions}>
            Stop recording coordinates
          </button>
        )}&nbsp;
        { clickedPositions && (
          <>
          <button onClick={resetPositionsArray}>Clear recorded positions</button>&nbsp;
          </>
        )}
      </div>
      {currentlySelectedPath && (
        <>
          <h1>{currentlySelectedPath.Name}</h1>
          {currentlySelectedPath.Family !== undefined && (
            <p>Part {currentlySelectedPath.Family.Number} of {currentlySelectedPath.Name}</p>
          )}
          <>
            <p>Path actions:&nbsp;</p>
            <button onClick={addAssociatedPaths}>Add asociated paths</button>&nbsp; 
            {!editingPath && (
              <>
                <button onClick={startEditingPath}>
                  Edit path coordinates
                </button>
                <p>New path coordinates: {JSON.stringify(pathBeingEdited)}</p>
              </>
            )}&nbsp;
            {editingPath && (
              <>
                <p>New path coordinates: {pathBeingEdited}</p>
                <button onClick={stopEditingPath}>
                  Finish editing this path coordinates
                </button>
              </>
            )}&nbsp;
          </>
        </>
      )}
      {recordPositions && (
      <>
        <p>
          Record positions clicked to get new path marked. Then copy the array of
          arrays and save it somewhere.
        </p>
        
        <p>Positions recorded: {JSON.stringify(clickedPositions)}</p>
      </>
      )}
    </>
  );
}
