import "./Menu.css";
import { NavLink } from "react-router-dom";

export default function Menu() {
  return (
    <div id="nav">
      <div id="nav-inner">
        <ul id="menu">
          <li>
            <NavLink 
                to={"/"}
                className={({isActive, isPending}) => isActive || isPending ? "active" : ""}
            >
                Home
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admin"}>Admin page</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
