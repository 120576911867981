// import "./AdminMainPage.css";
// import GoogleMaps from '../AdminComponents/AdminCreate/Create'
import GMaps from '../AdminComponents/AdminCreate/GMaps'
// import GMaps2023 from '../AdminComponents/AdminCreate/GMaps2023'

export default function AdminMainPage(){
    return <div className='AdminMainPage'>
        {/* <GoogleMaps /> */}
        <GMaps />
        {/* <GMaps2023 /> */}
    </div>
}