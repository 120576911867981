import { Outlet } from 'react-router-dom';
import Menu from '../Components/Menu/Menu';

function Layout(){
    return <div>
        <Menu />
        <Outlet />
    </div>
}

export default Layout;