// import React, { useRef, useState } from 'react';
import React, { useRef} from 'react';
import "./GMaps.css";
// import { GoogleMap, LoadScript, DrawingManager, Data, withScriptjs, withGoogleMap, Polygon, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { GoogleMap, DrawingManager, useJsApiLoader } from '@react-google-maps/api';

export default function GMaps() {
  // const mapRef = useRef();



  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const center = {
    lat: 59.80554470608132,
    lng: 15.136472342773452
  };

  // const onPolylineComplete = polyLine => {
  //   console.log(polyLine)
  //   // polyLine.toGeoJSON(),
  // }

  // const onLoad = drawingManager => {
  //   console.log(drawingManager)
  // }

  // return (!isLoaded ? null :
  //   <div id='gmap'>

  //     <LoadScript googleMapsApiKey="AIzaSyCc439g6Rc-ma4URggZLh5SoaYilNwGzfQ" libraries={["geometry", "drawing", "places"]}>
  //       <GoogleMap
  //         mapContainerStyle={containerStyle}
  //         center={center}
  //         zoom={8}
  //         onLoad={onLoadMap}
  //       >
  //         {/* <Data
  //           options={{
  //             controlPosition: window.google
  //               ? window.google.maps.ControlPosition.TOP_LEFT // TOP_CENTER
  //               : undefined,
  //             controls: ["Point"], // Polyline?
  //             drawingMode: "Polygon", //  "LineString" or "Polygon".
  //             featureFactory: (geometry) => {
  //               onPolylineComplete(geometry);
  //               console.log("geometry: ", geometry);
  //             },
  //             draggable: true,
  //             editable: true,
  //             strokeColor: "#00FF55",
  //             strokeOpacity: 1,
  //             strokeWeight: 2,
  //           }}
  //         /> */}

  //         {/* <DrawingManager
  //           onLoad={onLoad}
  //           onPolylineComplete={onPolylineComplete}
  //           map={this}
  //         />  */}

  //         {/* <DrawingManager
  //           defaultDrawingMode="Polyline"
  //           // defaultDrawingMode={google.maps.drawing.OverlayType.CIRCLE}
  //           defaultOptions={{
  //             drawingControl: true,
  //             drawingControlOptions: {
  //               position: 'TOP_CENTER',
  //               // position: window.google.maps.ControlPosition.TOP_CENTER,
  //               drawingModes: ["Polygon","Polyline"]
  //               //   window.google.maps.drawing.OverlayType.CIRCLE,
  //               //   window.google.maps.drawing.OverlayType.POLYGON,
  //               //   window.google.maps.drawing.OverlayType.POLYLINE,
  //               //   window.google.maps.drawing.OverlayType.RECTANGLE,
  //               // ],
  //             },
  //             circleOptions: {
  //               fillColor: `#ffff00`,
  //               fillOpacity: 1,
  //               strokeWeight: 5,
  //               clickable: false,
  //               editable: true,
  //               zIndex: 1,
  //             },
  //           }}
  //         /> */}

  //         {/* <DrawingManager
  //           onPolylineComplete={onPolylineComplete}
  //           map={this}
  //         />  */}

  //         <DrawingManager
  //           onLoad={onLoadDrawingManager}
  //           onOverlayComplete={onOverlayComplete}
  //           options={drawingManagerOptions}
  //         />
  //       </GoogleMap>
  //     </LoadScript>
  //   </div >
  // )

  
  const mapRef = useRef();
  const drawingManagerRef = useRef();
  const libraries = ['places', 'drawing'];
  
  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: 'AIzaSyCc439g6Rc-ma4URggZLh5SoaYilNwGzfQ',
  //   libraries
  // });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCc439g6Rc-ma4URggZLh5SoaYilNwGzfQ',
    libraries
  });

// const [center, setCenter] = useState(defaultCenter);
// constsetCenter] = useState(defaultCenter);

const drawingManagerOptions = {
  drawingControl: true,
  drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_CENTER,
      drawingModes: [
          window.google?.maps?.drawing?.OverlayType?.POLYLINE
      ]
  }
}
  const onLoadMap = (map) => {
    mapRef.current = map;
}

const onLoadDrawingManager = drawingManager => {
    drawingManagerRef.current = drawingManager;
}

const onOverlayComplete = ($overlayEvent) => {
  drawingManagerRef.current.setDrawingMode(null);
  // if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYLINE) {
  //     const newPolygon = $overlayEvent.overlay.getPath()
  //         .getArray()
  //         .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))

  //     // // start and end point should be same for valid geojson
  //     // const startPoint = newPolygon[0];
  //     // newPolygon.push(startPoint);
  //     // $overlayEvent.overlay?.setMap(null);
  //     // setPolygons([...polygons, newPolygon]);
  // }
}

  return (!isLoaded ? null :
    <div id='gmap'>

      <GoogleMap
        zoom={15}
        center={center}
        onLoad={onLoadMap}
        mapContainerStyle={containerStyle}
      >
        <DrawingManager
          onLoad={onLoadDrawingManager}
          onOverlayComplete={onOverlayComplete}
          options={drawingManagerOptions}
        />
      </GoogleMap>
    </div >
  );
}