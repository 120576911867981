import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from './Pages/Layout';
import MainPage from './Pages/MainPage';
import AdminMainPage from './Pages/AdminMainPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <div>ERROR! Page not found *insert unhappy face here*</div>,
    children: [
      {
        index: true,
        element: <MainPage />
      },
    ],
  },
  {
    path: "/admin",
    element: <Layout />,
    errorElement: <div>ERROR! Page not found *insert unhappy face here*</div>,
    children: [
      {
        index: true,
        element: <AdminMainPage />
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router = {router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
